import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import ContentfulPage from '../components/Page/ContentfulPage'

import Offices from '../components/offices'
import withContentfulBlocks from '../components/withContentfulBlocks'

class ContactIndex extends React.Component {
  render () {
    const offices = get(this, 'props.data.allContentfulLocation.edges')
    return (
      <ContentfulPage
        blocks={this.props.blocks}
        siteName={get(this.props, 'data.site.siteMetadata.siteName')}
        pageTitle={`${get(this.props, 'data.site.siteMetadata.siteName')} / Contact`}
        pageUrl={`${get(this.props, 'data.site.siteMetadata.url')}/contact`}
        pageDescription='Field is located in Abuja, Berlin, and Nairobi.'
      >
        <Page.Panel color='grey' spread>
          <Offices offices={offices} />
        </Page.Panel>
      </ContentfulPage>
    )
  }
}

export default withContentfulBlocks(ContactIndex)

export const pageQuery = graphql`
  query FIContactQuery {
    site {
      ...SiteMetaDataFragment
    }
    page: contentfulPage(contentful_id: { eq: "3Pg3BdWaI906sZcBESzi7V" }) {
      ...PageBlocksFragment
    }
    allContentfulLocation(sort: { fields: [title], order: ASC }) {
      edges {
        node {
          ...LocationFragment
        }
      }
    }
  }
`
